<template>
  <div ref="404-bounds" style="width: 100%; height: 700px; position: relative; overflow: visible">
    <div ref="404" class="noselect" @click="clicked404"
         style="position: relative; left: 1px; top: 1px; display: inline-block; text-align: center">
      <span style="font-size: 130px; font-weight: bold; line-height: 105px">{{ scoreL }}0{{ scoreR }}</span><br>
      <span style="font-size: 30px; font-weight: bold;">{{ $t(subText) }}</span>
    </div>
    <span v-if="game" ref="paddle-left" class="paddle left" style="top: 100px;"/>
    <span v-if="game" ref="paddle-right" class="paddle right" style="top: 100px;"/>
  </div>
</template>

<script>
import Mousetrap from 'mousetrap'

export default {
  name: 'PageNotFound',
  mounted() {
    this.xspeed = this.yspeed = this.baseSpeed
    this.$refs['404'].style.color = this.random_color()
    this.animate()
  },
  beforeUnmount() {
    this.running = false
  },
  data() {
    return {
      running: true,
      frameInterval: 1000 / 30, // 30 FPS
      baseSpeed: 3,
      xspeed: 3,
      yspeed: 3,
      hue: Math.random() * 256,
      clicks: 0,
      scoreL: 4,
      scoreR: 4,
      dirL: 0,
      dirR: 0,
      paddleSpeed: 16,
      game: false,
      speedIncreaseFactor: 3,
      speedIncreaseBase: 3,
      subText: 'views.page_not_found.invalid_url'
    }
  },
  methods: {
    animate() {
      const logo = this.$refs['404']
      const paddleL = this.$refs['paddle-left']
      const paddleR = this.$refs['paddle-right']
      if (logo) {
        const logo_rect = logo.getBoundingClientRect()
        const bounds_rect = this.$refs['404-bounds'].getBoundingClientRect()

        // window.console.log(parseInt(logo.style.left) + ' : ' + parseInt(logo.style.top))

        if (logo_rect.left <= bounds_rect.left) {
          this.xspeed = -this.xspeed
          this.bounce(logo)
          if (this.game) {
            const paddle_rect = this.$refs['paddle-left'].getBoundingClientRect()
            if (logo_rect.top > paddle_rect.bottom || logo_rect.bottom < paddle_rect.top) {
              if (this.scoreR === 9) {
                this.game = false
                this.subText = 'views.page_not_found.right_wins'
                this.clicks = 0
                this.xspeed = this.baseSpeed * Math.sign(this.xspeed)
                this.yspeed = this.baseSpeed * Math.sign(this.yspeed)
              } else this.scoreR += 1
              this.xspeed = this.baseSpeed * Math.sign(this.xspeed) + (this.xspeed - this.baseSpeed) / 2
              this.yspeed = this.baseSpeed * Math.sign(this.yspeed) + (this.yspeed - this.baseSpeed) / 2
              this.$nextTick(() => {
                logo.style.left = '1px'
              })
            } else {
              this.xspeed += ((Math.random() - 0.75) * this.speedIncreaseFactor + this.speedIncreaseBase) * Math.sign(this.xspeed)
              this.yspeed += ((Math.random() - 0.75) * this.speedIncreaseFactor + this.speedIncreaseBase) * Math.sign(this.yspeed)
            }
          }
        } else if (logo_rect.right >= bounds_rect.right) {
          this.xspeed = -this.xspeed
          this.bounce(logo)
          if (this.game) {
            const paddle_rect = this.$refs['paddle-right'].getBoundingClientRect()
            if (logo_rect.top > paddle_rect.bottom || logo_rect.bottom < paddle_rect.top) {
              if (this.scoreL === 9) {
                this.game = false
                this.subText = 'views.page_not_found.left_wins'
                this.clicks = 0
                this.xspeed = this.baseSpeed * Math.sign(this.xspeed)
                this.yspeed = this.baseSpeed * Math.sign(this.yspeed)
              } else this.scoreL += 1
              this.xspeed = this.baseSpeed * Math.sign(this.xspeed) + (this.xspeed - this.baseSpeed) / 2
              this.yspeed = this.baseSpeed * Math.sign(this.yspeed) + (this.yspeed - this.baseSpeed) / 2
              this.$nextTick(() => {
                logo.style.left = bounds_rect.width - logo_rect.width - 1 + 'px'
              })
            } else {
              this.xspeed += ((Math.random() - 0.75) * this.speedIncreaseFactor + this.speedIncreaseBase) * Math.sign(this.xspeed)
              this.yspeed += ((Math.random() - 0.75) * this.speedIncreaseFactor + this.speedIncreaseBase) * Math.sign(this.yspeed)
            }
          }
        }

        if (logo_rect.top <= bounds_rect.top) {
          this.yspeed = -this.yspeed
          this.bounce(logo)
        } else if (logo_rect.bottom >= bounds_rect.bottom) {
          this.yspeed = -this.yspeed
          this.bounce(logo)
        }

        logo.style.left = parseInt(logo.style.left) + this.xspeed + 'px'
        logo.style.top = parseInt(logo.style.top) + this.yspeed + 'px'

        if (this.game) {
          paddleL.style.top = parseInt(paddleL.style.top) + this.dirL * this.paddleSpeed + 'px'
          paddleR.style.top = parseInt(paddleR.style.top) + this.dirR * this.paddleSpeed + 'px'
        }

        window.requestAnimationFrame(this.animate)
      }
    },
    random_color() {
      this.hue += Math.random() * 180 + 90
      return `hsl(${this.hue}, ${Math.random() * 25 + 75}%, ${Math.random() * 50 + 25}%)`
    },
    clicked404() {
      this.clicks += 1
      if (this.clicks === 5) {
        this.start_game()
      }
    },
    start_game() {
      this.game = true
      this.scoreL = 0
      this.scoreR = 0
      this.subText = 'views.page_not_found.invalid_url'
      const self = this
      Mousetrap.bind('up', function (e) {
        e.preventDefault()
        self.dirR = -1
      })
      Mousetrap.bind('down', function (e) {
        e.preventDefault()
        self.dirR = 1
      })
      Mousetrap.bind('w', function (e) {
        e.preventDefault()
        self.dirL = -1
      })
      Mousetrap.bind('s', function (e) {
        e.preventDefault()
        self.dirL = 1
      })
      Mousetrap.bind('up', function (e) {
        e.preventDefault()
        self.dirR = 0
      }, 'keyup')
      Mousetrap.bind('down', function (e) {
        e.preventDefault()
        self.dirR = 0
      }, 'keyup')
      Mousetrap.bind('w', function (e) {
        e.preventDefault()
        self.dirL = 0
      }, 'keyup')
      Mousetrap.bind('s', function (e) {
        e.preventDefault()
        self.dirL = 0
      }, 'keyup')
    },
    bounce(logo) {
      logo.style.color = this.random_color()
      if (this.game) this.speed += 1
    }
  }
}
</script>

<style lang="scss" scoped>
.paddle {
  position: absolute;
  background: black;
  width: 5px;
  height: 50px;
}

.paddle.left {
  left: 0;
}

.paddle.right {
  right: 0;
}
</style>
